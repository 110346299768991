import React from 'react'
import Menu from 'react-burger-menu/lib/menus/slide'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@chakra-ui/react'

export const HomeBurgerMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleMenuStateChange = (state) => {
    // メニューの状態が変更されたときの処理
    setIsOpen(state.isOpen)
  }

  return (
    <Menu isOpen={isOpen} onStateChange={handleMenuStateChange} right>
      <div id='page-wrap'>
        <div id='menu-wrap'>
          <a href='/fashion'>toel Fashion</a>
          <a href='/gray'>toel Gray</a>
          <Link as={RouterLink} to='/fashion/#dealers'>
            DEALERS
          </Link>
        </div>
      </div>
    </Menu>
  )
}

export default HomeBurgerMenu
