import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ModalWrapper from '../ui/component/fashion/ModalWrapper.tsx'
import TopContent from '../ui/component/fashion/TopContent.tsx'
import MainContent from '../ui/component/fashion/MainContent.tsx'
import Header from '../ui/component/common/Header.tsx'

const Fashion = () => {
  const [imgPath, setImgPath] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  // ディーラーへのリンクをクリックしたときにスクロールする
  // 遅延させることでスムーズにスクロールさせる
  useEffect(() => {
    if (location.hash === '#dealers') {
      setTimeout(() => {
        const element = document.getElementById('dealers')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 100) // 100ms 遅延
    }
  }, [location])

  return (
    <main>
      <Header />
      <TopContent />
      <MainContent setIsOpen={setIsOpen} setImgPath={setImgPath} />

      {/* カラーチャートのクリック時 */}
      <ModalWrapper
        imgPath={imgPath}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </main>
  )
}

export default Fashion
