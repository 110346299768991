import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import CTA from './CTA.tsx'

export const InterviewBox = () => {
  return (
    <Box className='section'>
      <Box id='interview' className='title'>
        INTERVIEW
      </Box>
      <Box className='interviewBox'>
        <Box className='interviewPersonWrapper'>
          <Image
            src='/img/interview_1.jpg'
            fit='cover'
            boxSize={[360, 360, 360, 300]}
          />
          <Box style={{ textAlign: 'left' }}>
            <Box className='interviewNameWrapper lora'>阿部 清貴さん</Box>
            <Box className='interviewCompany lora'>
              ONE’s Group 代表取締役 <br />
              美容を主軸にしたベンチャー企業
            </Box>
          </Box>
        </Box>
        <Box style={{ minWidth: '70%' }}>
          <Text color={'white'} pl={[0, 10]} pr={[0, 10]}>
            トエルカラーはONE'sグループの主軸カラー剤です。
            <br />
            <br />
            ブリーチ率8割の当グループも開発に携わり、既存のメーカーに比べても
            <br className='spDisplayNone' />
            ハイクオリティーなペールトーンを出すことができるカラー剤です。
            <br />
            また、当グループは1年目からデビューされる若手主体のサロン展開になります。
            <br className='spDisplayNone' />
            カラーの成功率を上げるのを目的とした開発をしております。
            そして、美容室を運営する上で大事になるのが材料費です。
            <br />
            美容室が共同開発に携わっているからこそ実現できる
            <br className='spDisplayNone' />
            コストパフォーマンスが実現しております。
            <br />
            <br />
            メーカー側もとても革命意欲があり美容室企業に
            <br className='spDisplayNone' />
            本気で向き合っているクレバーな企業です。
            <br />
            <br />
            メインカラーとして活躍させた場合
            <br />
            1.クオリティーの向上&emsp;
            <br className='pcDisplayNone' />
            2.教育の最速化&emsp;
            <br className='pcDisplayNone' />
            3.材料費の削減&emsp;
            <br className='pcDisplayNone' />
            4.施術ミスの低下 などのメリットを得ることができます。
          </Text>
        </Box>
      </Box>
      <Box className='interviewBox'>
        <Box className='interviewPersonWrapper'>
          <Image
            src='/img/interview_2.jpg'
            fit='cover'
            boxSize={[360, 360, 360, 300]}
          />
          <Box>
            <Box className='interviewNameWrapper lora'>森田 拓樹さん</Box>
            <Box className='interviewCompany lora'>
              株式会社Afinar 代表取締役 <br />
              株式会社F.O.S 取締役 <br />
              Tiam shibuya代表
            </Box>
          </Box>
        </Box>
        <Box>
          <Text color={'white'} pl={[0, 10]} pr={[0, 10]}>
            トエルカラーとの出会いはアライアンスを結んだONE'sさんからのご紹介でした。
            <br />
            <br />
            メーカーさんから直接開発への熱意、美容業界への思いを伺い、
            <br className='spDisplayNone' />
            私たちも一緒にトエルカラーを育てていきたいと考えております。
            <br />
            <br />
            トエルカラーの私の感覚ですが、ファッションカラーにおいて高難易度のペールトーンの原色の発色表現を容易にし、なおかつ基幹カラー剤として幅広いお客様に対応できる非常に今の時代にあったフレキシブルなカラー剤だと思います。
            <br />
            コストにおいても原材料費高の中で企業努力を重ねられています。
            <br />
            <br />
            「コスパとスペックを併せ持った次世代に新基軸カラー剤」だと私は感じています。
          </Text>
        </Box>
      </Box>
      <Box className='interviewBox'>
        <Box className='interviewPersonWrapper'>
          <Image
            src='/img/interview_3.jpg'
            fit='cover'
            boxSize={[360, 360, 360, 300]}
          />
          <Box style={{ textAlign: 'left' }}>
            <Box className='interviewNameWrapper lora'>熊谷 安史さん</Box>
            <Box className='interviewCompany lora'>
              ACQUA　総店長 <br />
            </Box>
          </Box>
        </Box>
        <Box>
          <Text color={'white'} pl={[0, 10]} pr={[0, 10]}>
            カラーの明度と彩度の幅が広く、色味それぞれに個性があるカラー剤です。
            <br />
            <br />
            ベーシックカラーは、色の棲み分けがあるのに、失敗のない色味のバランスが絶妙！
            <br />
            ３トーンからハイトーンまで、個性豊かな調合か可能なので、
            <br className='spDisplayNone' />
            薬剤選定はワクワクしますね。
            コントロールカラーは、トレンドに合った、クリエイティブなハイトーンや、鮮明な色味を出したい時に活躍。
            <br />
            <br />
            白髪染めは、色抜けの心配がなく、しっかり染まります。
            <br />
            ベーシックカラーとの混ぜ合わせで、白髪がちらほら気になってきたお客様にも透明感のあるヘアカラーが提案できます。
            <br />
            頭皮が敏感になってきたエイジング世代の方にも低刺激で好評です。
            <br />
            <br />
            これからも時代に合ったデザインが提供できる商材をたくさん作り続けてほしいです。
          </Text>
        </Box>
      </Box>
      <Box className='interviewBox'>
        <Box className='interviewPersonWrapper'>
          <Image
            src='/img/interview_4.jpg'
            fit='cover'
            boxSize={[360, 360, 360, 300]}
          />
          <Box>
            <Box className='interviewNameWrapper lora'>望月 裕介さん</Box>
            <Box className='interviewCompany lora'>
              株式会社U-topia WORLD 代表取締役
            </Box>
          </Box>
        </Box>
        <Box>
          <Text color={'white'} pl={[0, 10]} pr={[0, 10]}>
            【美容師のために生まれた、超高彩度カラー剤で新しい魅力を引き出す】
            <br />
            我々U-topiaでは、コストパフォーマンスに優れた超高彩度のカラー剤を採用しています。
            <br />
            トエルカラーは発色の鮮やかさはもちろん、リーズナブルでサロンにとってもお客様にとっても最適なカラーリングが実現可能です。
            <br />
            <br />
            インターコスメさんは「超美容室現場主義」を徹底しており、美容師が現場で本当に求める製品を作り上げています。
            <br />
            美容師の声をしっかりと反映した製品だからこそ、私たちも自信を持ってお客様におすすめしています。
            <br />
            <br />
            最高の発色を実現するこのカラー剤を是非、体験してみてください。
            <br />
            髪色の新しい可能性が、ここから広がります。
          </Text>
        </Box>
      </Box>
      <Box className='developerWrapper'>
        <Box className='developerTextWrapper'>
          <Text fontSize='3xl' mb={5} className='lora'>
            開発者の想い
          </Text>
          <Text color={'white'}>
            トエルカラーは濁りのない高彩度カラーなのでミックスすることによりどんな色相の色味も自由自在に作れます。
            <br />
            雑味のない色味なので複雑な履歴、ダメージにも対応可能です。
            <br />
            ACを使用することによりデリケートな既染部、ダメージ部へのカラーリングが穏やかに施術できます。
            <br />
            <br />
            また、ek:の追加により今までのアルカリカラーでは出せなかった超高彩度の領域までの表現が可能になりました！
          </Text>
        </Box>
        <Box className='developerImageWrapper'>
          <Image
            src='/img/developer.png'
            fit='cover'
            boxSize={[360, 360, 360, 300]}
          />
        </Box>
      </Box>
      <CTA />
    </Box>
  )
}

export default InterviewBox
