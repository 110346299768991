import React from 'react'
import { Box, Button, Link } from '@chakra-ui/react'

type Props = {}

export const CTA = (props: Props) => {
  return (
    <Box className='cta-wrapper'>
      <Button
        size={'lg'}
        background='#9F8F92'
        color={'white'}
        _hover={{ bg: '#bdc3c7' }}
        className='lora'
      >
        <Link href='http://www.inter-cosme.com/customer-service' isExternal>
          お問い合わせはこちら
        </Link>
      </Button>
    </Box>
  )
}

export default CTA
