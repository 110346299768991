import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import ToelGray from '../gray/ToelGray.tsx'
import Feature from '../gray/Feature.tsx'
import SideBar from '../common/SideBar.tsx'
import CoverLineImage from '../gray/CoverLineImage.tsx'
import BlurLineImage from '../gray/BlurLineImage.tsx'
import LineUp from '../gray/LineUp.tsx'
import ColorChartBox from './ColorChartBox.tsx'
import Interview from './Interview.tsx'
import Dealer from './Dealer.tsx'
import Footer from '../common/Footer.tsx'

type Props = {
  setIsOpen: any
  setImgPath: any
}

export const MainContent = (props: Props) => {
  return (
    <Flex>
      <SideBar />

      <Box>
        <ToelGray />
        <Feature />
        <CoverLineImage />
        <BlurLineImage />
        <LineUp />
        <ColorChartBox
          setIsOpen={props.setIsOpen}
          setImgPath={props.setImgPath}
        />
        <Interview />
        <Dealer />
        <Footer />
      </Box>
    </Flex>
  )
}

export default MainContent
