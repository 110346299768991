import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import theme from './ui/theme/Theme.tsx'
import Home from './pages/Home.tsx'
import ColorRecipe from './pages/ColorRecipe.tsx'
import ColorRecipeDetail from './pages/ColorRecipeDetail.tsx'
import Fashion from './pages/Fashion.tsx'
import Gray from './pages/Gray.tsx'

import './style/main.css'
import './style/pc.css'
import './style/sp.css'

// Google Tag Managerの設定
const tagManagerArgs = {
  gtmId: 'GTM-PK7WGMDW',
}

// Appコンポーネント
function App() {
  // Google Tag Managerを初期化
  TagManager.initialize(tagManagerArgs)

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/color-recipe' element={<ColorRecipe />} />
          <Route path='/color-recipe/:id' element={<ColorRecipeDetail />} />
          <Route path='/fashion' element={<Fashion />} />
          <Route path='/gray' element={<Gray />} />
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

export default App
