import React from 'react'
import { Box, Flex, Text, Image } from '@chakra-ui/react'
import FeatureImage from './FeatureImage.tsx'

export const Feature = () => {
  return (
    <Box className='section'>
      <Box id='feature' className='gray-title'>
        FEATURE
      </Box>
      <Box className='featureWrapper'>
        <FeatureImage n={0} />
        <FeatureImage n={1} className='spDisplayNone' />
        <FeatureImage n={2} className='spDisplayNone' />
      </Box>

      <Box position={'relative'} mb={10}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          mt={{ base: 0, lg: 10 }}
        >
          <Box flex='1'>
            <Text fontSize={{ base: 'lg', lg: 'xl' }} fontWeight={'bold'}>
              色彩豊かで高濃度の
              <br />
              「ベースチューニング処方」
            </Text>
            <Text fontSize={{ base: 'sm', lg: 'lg' }} mb={{ base: 2, lg: 4 }}>
              従来のグレイカラーの染料設計※当社比
            </Text>
            <Text fontSize='md'>
              ベース染料に褪色染料を多く配合しているため、
              <br className='spDisplayNone' />
              白髪をしっかり染めようとすると重く見えてしまう。
              <br className='spDisplayNone' />
              一方、ベース染料を薄めると色みは出るが白髪が浮いてしまう。
            </Text>
          </Box>
          <Box flex='1'>
            <Image src='/img/gray_feature_text_1.png' />
          </Box>
        </Flex>

        <Flex direction={{ base: 'column', lg: 'row' }} mt={{ base: 3, lg: 5 }}>
          <Box flex='1'>
            <Text
              fontSize={{ base: 'lg', lg: 'xl' }}
              mb={{ base: 2, lg: 4 }}
              fontWeight={'bold'}
            >
              NEWトエルカラーGの染料設計
              <br />
              「ベースチューニング処方」
            </Text>
            <Text fontSize='md'>
              褪色染料を最低限に抑えた色調ごとのベース染料を設計。
              <br className='spDisplayNone' />
              且つ、彩度系染料を高濃度で配合。
              <br className='spDisplayNone' />
              色彩豊かで重さを感じず、色みも濃いため、白髪をしっかりとカバーすることができる。
            </Text>
          </Box>
          <Box flex='1'>
            <Image src='/img/gray_feature_text_2.png' />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default Feature
