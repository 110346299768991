import React from 'react'
import { Box, Flex, Link, Image, Center, Text } from '@chakra-ui/react'

export const HomeFooter = () => {
  return (
    <Box as='footer' mt='auto' p={[1, 5]} backgroundColor='white'>
      <Flex justify={'flex-end'} gap={4} mr={5}>
        <Link href='http://www.inter-cosme.com' isExternal>
          <Image src='/img/intercosme.png' h='3vh' />
        </Link>
        <Link href='https://www.instagram.com/toel_official/?hl=ja' isExternal>
          <Image src='/img/iq_black.png' h='3vh' />
        </Link>
      </Flex>
      <Center mt={5}>
        <Text fontSize={['xs', 'md']} color='gray'>
          Copyright InterCosme Co., Ltd. All Rights Reserved
        </Text>
      </Center>
    </Box>
  )
}

export default HomeFooter
