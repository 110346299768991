import React from 'react'
import { AbsoluteCenter, Box, Image, Center, Text } from '@chakra-ui/react'

type Props = {
  n: number
}

export const FeatureImage = (props: Props) => {
  const img = ['feature_1.jpg', 'feature_2.jpg', 'feature_3.jpg']
  const sub = ['FEATURE 01.', 'FEATURE 02.', 'FEATURE 03.']
  const head = [
    '圧倒的な彩度表現',
    '優れた色持ちと操作性',
    '色鮮やかな薬剤色で注目度抜群。',
  ]
  const body = [
    `
髪表面と深部を2層で染色することで、
今までアルカリカラーでは不可能とされていた
超高彩度な色彩表現まで可能に。`,
    `
髪への密着性を向上し、優れた染まりと色持ちを実現。
水分と油分のバランスにこだわった薬剤は、
時間が経っても硬くなりにくく、
なめらかで塗布しやすく操作性も抜群。`,
    `
見た目にもインパクトのある薬剤色は
施術中から注目の的。
お客さまも驚きと期待感で自然と笑顔に。
`,
  ]

  return (
    <Box position={'relative'} color={'white'}>
      <AbsoluteCenter
        className='featureContent'
        bg={'rgba(0,0,0, 0.6)'}
        axis='both'
      >
        <Box className='featureBoxComp'>
          <Center>
            <Text fontSize='2xl' className='lora'>
              {sub[props.n]}
            </Text>
          </Center>
          <Center>
            <Text fontSize='md' className='lora' whiteSpace='pre-line'>
              {head[props.n]}
            </Text>
          </Center>
          <Box h={4}></Box>
          <Center>
            <Text fontSize='xs'>{body[props.n]}</Text>
          </Center>
        </Box>
      </AbsoluteCenter>
      <Image src={'/img/' + img[props.n]} w={'100%'} h={'100%'}></Image>
    </Box>
  )
}

export default FeatureImage
