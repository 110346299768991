import React from 'react'
import { AbsoluteCenter, Box, Image, Center, Text } from '@chakra-ui/react'

type Props = {
  n: number
  className?: string // クラス名をオプションとして追加
}

export const FeatureImage = ({ n, className }: Props) => {
  const img = ['gray_feature_1.jpg', 'gray_feature_2.jpg', 'gray_feature_3.jpg']
  const sub = ['FEATURE 01.', 'FEATURE 02.', 'FEATURE 03.']
  const head = [
    '重くないのにしっかり染まる',
    `大人世代の悩みに応え、
カラーリングの楽しさを提案できる`,
    `8Lv,9Lvの染まりの向上と、
    在庫負担の軽減ができる`,
  ]
  const body = [
    `色彩豊かで高濃度の染料設計により、
    重さを感じない色みと白髪への確実な
染まりが両立できる。`,
    `2つのラインナップで、大人世代のお客様の悩みに応え、将来的なカラーリング計画や楽しさを提案できる。`,
    `単色では白髪への染まりが悪い8Lv, 9Lvを、簡単な加減法使って染まりを向上することができる。同時に在庫負担の軽減になる。`,
  ]

  return (
    <Box position={'relative'} color={'black'} className={className}>
      <AbsoluteCenter
        className='featureContent'
        bg={'rgba(246, 242, 242, 0.6)'}
        axis='both'
      >
        <Box className='featureBoxComp'>
          {/* SPではFEATURE~~は表示しない */}
          <Center className='spDisplayNone'>
            <Text fontSize='2xl' className='lora'>
              {sub[n]}
            </Text>
          </Center>

          <Center>
            <Text
              fontSize='md'
              fontWeight={'bold'}
              className='lora'
              whiteSpace='pre-line'
            >
              {head[n]}
            </Text>
          </Center>
          <Box h={4}></Box>
          <Center>
            <Text fontSize='sm' whiteSpace='pre-line'>
              {body[n]}
            </Text>
          </Center>
        </Box>
      </AbsoluteCenter>
      <Image src={'/img/' + img[n]} w={'100%'} h={'100%'}></Image>
    </Box>
  )
}

export default FeatureImage
