import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ModalWrapper from '../ui/component/fashion/ModalWrapper.tsx'
import SideBar from '../ui/component/common/SideBar.tsx'
import TopContent from '../ui/component/gray/TopContent.tsx'
import MainContent from '../ui/component/gray/MainContent.tsx'
import Header from '../ui/component/common/Header.tsx'

const Gray = () => {
  const [imgPath, setImgPath] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  // ディーラーへのリンクをクリックしたときにスクロールする
  // 遅延させることでスムーズにスクロールさせる
  useEffect(() => {
    if (location.hash === '#dealers') {
      setTimeout(() => {
        const element = document.getElementById('dealers')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 100) // 100ms 遅延
    }
  }, [location])

  return (
    <main style={{ backgroundColor: 'white', color: 'black' }}>
      <Header />
      <TopContent />
      <MainContent setIsOpen={setIsOpen} setImgPath={setImgPath} />
      <SideBar />

      {/* カラーチャートのクリック時 */}
      <ModalWrapper
        imgPath={imgPath}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </main>
  )
}

export default Gray
