import React from 'react'
import { Box, Flex, Link, Image, Center, Text } from '@chakra-ui/react'

export const Footer = () => {
  return (
    <Box
      position='absolute'
      left={0}
      right={0}
      pt={5}
      pb={5}
      backgroundColor='black'
      color='white'
    >
      <Center mb={5}>
        <Text fontSize={['xs', 'md']}>
          全ての画像の無断転載・無断引用は禁止です。
        </Text>
      </Center>
      <Flex justify='center' align='center' gap={2}>
        <Link href='https://www.instagram.com/toel_official/?hl=ja' isExternal>
          <Image src='/img/ig.png' boxSize={30} />
        </Link>
        <Link href='https://www.instagram.com/toel_official/?hl=ja' isExternal>
          <Text>FOLLOW US</Text>
        </Link>
      </Flex>
      <Center mt={5}>
        <Text fontSize={['xs', 'md']}>
          Copyright InterCosme Co., Ltd. All Rights Reserved
        </Text>
      </Center>
    </Box>
  )
}

export default Footer
