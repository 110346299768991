import React, { useState } from 'react'
import { Box, Flex, Text, Image, SimpleGrid } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import colorRecipes from '../../../libs/ColorRecipes.ts'
import Sidebar from './Sidebar.tsx'
import {
  colorCategories,
  getColorCategoryFromCategoryId,
} from '../../../libs/ColorCategories.ts'

const ColorRecipeGallery = () => {
  const [selectedColorCategoryId, setSelectedColorCategoryId] = useState<
    number | null
  >(null)
  const filteredColorRecipes = selectedColorCategoryId
    ? colorRecipes.filter(
        (colorRecipe) =>
          colorRecipe.colorCategoryId === selectedColorCategoryId,
      )
    : colorRecipes

  return (
    <Flex>
      <Sidebar
        colorCategories={colorCategories}
        setSelectedColorCategoryId={setSelectedColorCategoryId}
      />
      <Box flex='1' p={[2, 8]}>
        <Flex justifyContent='center' mb={[0, 8]} mt={[4, 0]}>
          <Text className='title'>Color Recipe Gallery</Text>
        </Flex>
        <SimpleGrid columns={[2, 2, 2, 3, 4]} spacing={[2, 8]}>
          {filteredColorRecipes.map((colorRecipe) => (
            <Link to={`/color-recipe/${colorRecipe.id}`} key={colorRecipe.id}>
              <Box key={colorRecipe.id}>
                <Box mb={4} p={[2, 4]}>
                  <Image
                    src={`/recepes/${colorRecipe.id}-1.jpg`}
                    alt={colorRecipe.colorRecipe}
                    mb={2}
                  />
                  <Text fontWeight='bold'>{colorRecipe.styleName}</Text>
                  <Flex justifyContent='flex-end'>
                    <Text
                      display='inline-block'
                      border='2px'
                      borderColor={
                        getColorCategoryFromCategoryId(
                          colorRecipe.colorCategoryId,
                        )?.color
                      }
                      borderRadius='md'
                      pr={1}
                      pl={1}
                      backgroundColor={
                        getColorCategoryFromCategoryId(
                          colorRecipe.colorCategoryId,
                        )?.color
                      }
                      fontWeight={'semibold'}
                      color={'black'}
                    >
                      {
                        getColorCategoryFromCategoryId(
                          colorRecipe.colorCategoryId,
                        )?.name
                      }
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </Link>
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  )
}

export default ColorRecipeGallery
