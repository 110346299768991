import React from 'react'
import { Box } from '@chakra-ui/react'
import DealerTable from '../common/DealerTable.tsx'

export const Dealer = () => {
  return (
    <Box className='section'>
      <h1 id='dealers' className='gray-title'>
        ALL DEALERS
      </h1>
      <DealerTable color='black' />
    </Box>
  )
}

export default Dealer
