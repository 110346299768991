import React from 'react'
import CTA from './CTA.tsx'
import { AnimatedComponentFromDown } from '../common/AnimationWrapper.tsx'
import { Box, Text, Image } from '@chakra-ui/react'

export const TopContent = () => {
  return (
    <Box mb={[5, 5, '80px']} position='relative'>
      <Image src='/img/gray_top.png' width={'100%'} />
      <Image
        src='/img/logo_white.png'
        position='absolute'
        top='35%'
        left='50%'
        transform='translate(-50%, -50%)' // 中央揃え
        textAlign='center'
        className='spDisplayNone'
        w={'400px'}
      />

      <Box position={'relative'}>
        <Image
          src='/img/gray_txbg_pc.jpg'
          width={'100%'}
          className='spDisplayNone'
        />
        <Image
          src='/img/gray_txbg_sp.jpg'
          width={'100%'}
          className='pcDisplayNone'
        />
        <Box className='top-text-wrapper'>
          <AnimatedComponentFromDown>
            <Text
              className='lora'
              fontSize={['lg', '2xl', '3xl']}
              align={'center'}
              color={'black'}
              pl={{ base: '0', lg: '40vh' }}
              fontWeight={'bold'}
            >
              大人世代を楽しもう。
            </Text>
            <Text
              className='lora'
              fontSize={['sm', 'xl']}
              align={'center'}
              color={'black'}
              pl={{ base: '0', lg: '40vh' }}
              pt='30px'
            >
              大人世代の髪悩み、カラーリング。
              <br />
              <br />
              「白髪をしっかり染めようとすると、重く見えてしまう。」
              <br />
              「綺麗な色に染めようとすると、白髪が浮いてしまう。」
              <br />
              <br />
              NEWトエルカラーGは、
              <br />
              “重さを感じない色み”と、“白髪への確実な染まり”を両立。
              <br />
              <br />
              もっと、ずっと、これからも。
              <br />
              白髪に縛られず、大人世代を楽しもう！
            </Text>
          </AnimatedComponentFromDown>
          <CTA />
        </Box>
      </Box>
    </Box>
  )
}

export default TopContent
