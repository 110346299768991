import React from 'react'
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const MainSections = () => {
  const navigate = useNavigate()

  return (
    <Box className='pcDisplayNone' w='100%'>
      {/* Hero セクション */}
      <Flex
        h='30vh'
        bg='gray.500'
        align='center'
        justify='center'
        direction='column'
      >
        <Text
          fontSize='6xl'
          fontWeight='bold'
          color='white'
          letterSpacing='widest'
        >
          + o e L
        </Text>
        <Text fontSize='lg' color='white' mt={2} textAlign='center'>
          hair <br />
          coloring <br />
          agent
        </Text>
      </Flex>

      {/* Fashion & Gray セクション */}
      <Flex h='50vh'>
        {/* Fashion セクション */}
        <Box
          flex='1'
          bg='black'
          color='white'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          onClick={() => navigate('/fashion')}
        >
          <Image src='/img/sp_fashion.png' h='200px' mb={4} />
          <Text fontSize='2xl' fontWeight='bold'>
            Fashion
          </Text>
          <Button
            mt={2}
            backgroundColor={'white'}
            color={'black'}
            _hover={'none'}
          >
            MORE
          </Button>
        </Box>

        {/* Gray セクション */}
        <Box
          flex='1'
          bg='white'
          color='black'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          onClick={() => navigate('/gray')}
        >
          <Image
            src='/img/sp_gray.png'
            h='200px'
            mb={4}
            border='0'
            boxShadow='none'
          />
          <Text fontSize='2xl' fontWeight='bold'>
            Gray
          </Text>
          <Button
            mt={2}
            backgroundColor={'black'}
            color={'white'}
            _hover={'none'}
          >
            MORE
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default MainSections
