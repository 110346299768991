import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'

export const Interview = () => {
  return (
    <Box className='section'>
      <Box id='interview' className='gray-title'>
        INTERVIEW
      </Box>
      <Box>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          mb={{ base: 10, lg: 0 }}
        >
          <Box flex='1' display='flex'>
            <Image src='/img/gray_interview_main.jpg' objectFit='contain' />
          </Box>

          <Box flex='2'>
            {/* Interview セクション */}
            <Box mb={{ base: 0, lg: 5 }}>
              <Text
                fontSize={{ base: 'lg', lg: '2xl' }}
                fontWeight={'bold'}
                mb={{ base: 2, lg: 4 }}
                mt={{ base: 2, lg: 0 }}
                ml={{ base: 0, lg: 10 }}
              >
                開発協力 / Memories代表 渡来 俊彦 氏
              </Text>
              <Text
                fontSize={{ base: 'md', lg: 'md' }}
                ml={{ base: 0, lg: 10 }}
              >
                "白髪ぼかしは調合や施術が難しい"というお話をよく耳にします。
                <br className='spDisplayNone' />
                <br />
                リニューアルしたトエルカラーグレイは流行りの白髪ぼかしを簡単・きれいに作れるカラー剤です。
                <br className='spDisplayNone' />
                <br />
                ブラーラインは白髪が馴染みやすい高明度薬剤で、ハイライト後のオンカラーではキレイな発色でコントラストを強調できる。
                <br className='spDisplayNone' />
                <br />
                カバーラインは重さを感じさせず、色彩豊かに白髪をしっかり染め上げ、いつものカラーリングをワンランク上の仕上がりに。
                <br className='spDisplayNone' />
                <br />
                混ぜる事で色や明度表現の幅が広がり、お客様への提案が楽しくなります。
                <br className='spDisplayNone' />
                <br />
                カラーリング後の艶感や質感もとても良いので、是非活用いただきたいカラー剤です。
                <br />
              </Text>
            </Box>
          </Box>
        </Flex>
        {/* Memories セクション */}
        <Box
          bgColor='gray.200'
          pt={{ base: 0, lg: 2 }}
          pb={{ base: 0, lg: 4 }}
          className='spDisplayNone'
        >
          <Text
            fontSize={{ base: 'lg', lg: '2xl' }}
            fontWeight='bold'
            mb={{ base: 1, lg: 2 }}
            ml={{ base: 0, lg: 10 }}
          >
            Memories
          </Text>
          <Flex gap={2} justify='center'>
            <Image
              src='/img/gray_interview_logo.jpg'
              w='250px'
              objectFit='cover'
            />
            <Image
              src='/img/gray_interview_shop.png'
              w='250px'
              objectFit='cover'
            />
            <Image
              src='/img/gray_interview_staff.png'
              w='250px'
              objectFit='cover'
            />
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default Interview
