import React, { useState, useRef } from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Link,
} from '@chakra-ui/react'
import colorRecipes, { ColorRecipe } from '../../../libs/ColorRecipes.ts'
import Slider from 'react-slick'
import { getColorCategoryFromCategoryId } from '../../../libs/ColorCategories.ts'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface ColorRecipeDetailComponentProps {
  id: number | undefined
}

const ColorRecipeDetailComponent: React.FC<ColorRecipeDetailComponentProps> = ({
  id,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef<Slider>(null)

  const getColorRecipeFromId = (
    colorRecipeId: number,
  ): ColorRecipe | undefined => {
    return colorRecipes.find((colorRecipe) => colorRecipe.id === colorRecipeId)
  }

  const matchedColorRecipe = id ? getColorRecipeFromId(id) : undefined

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
    arrows: false,
    centerMode: true,
    centerPadding: '20px',
  }

  const goToSlide = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index)
    }
  }

  const colorCategory = matchedColorRecipe
    ? getColorCategoryFromCategoryId(matchedColorRecipe.colorCategoryId)
    : undefined

  return (
    <Box bg='black' minHeight='100vh' py={12}>
      {matchedColorRecipe && (
        <Flex
          flexDirection={['column', 'row']}
          alignItems='center'
          maxWidth='1200px'
          mx='auto'
          px={4}
        >
          <Box width={['100%', '50%']} mb={[8, 0]}>
            <Slider ref={sliderRef} {...settings}>
              {[1, 2, 3].map((index) => (
                <Box
                  key={index}
                  onClick={() => goToSlide(index)}
                  cursor='pointer'
                  px={2}
                  _focus={{ outline: 'none' }}
                >
                  <Image
                    src={`/recepes/${id}-${index}.jpg`}
                    alt={`${matchedColorRecipe.styleName} ${index}`}
                    objectFit='cover'
                    width='100%'
                    height='auto'
                    borderRadius='md'
                    boxShadow='lg'
                    transition='transform 0.3s'
                    _hover={{ transform: 'scale(1.05)' }}
                    borderColor={colorCategory?.color}
                  />
                </Box>
              ))}
            </Slider>
            <Flex justifyContent='center' mt={4}>
              {[1, 2, 3].map((index) => (
                <Box
                  key={index}
                  borderWidth={2}
                  borderColor={
                    currentSlide === index - 1 ? colorCategory?.color : 'white'
                  }
                  onClick={() => goToSlide(index - 1)}
                  cursor='pointer'
                  mx={2}
                >
                  <Image
                    src={`/recepes/${id}-${index}.jpg`}
                    alt={`${matchedColorRecipe.styleName} ${index}`}
                    objectFit='cover'
                    boxSize='100px'
                    boxShadow='lg'
                    transition='transform 0.3s'
                    _hover={{ transform: 'scale(1.1)' }}
                  />
                </Box>
              ))}
            </Flex>
          </Box>
          <Box width={['100%', '50%']} pl={[0, 8]} color='white'>
            <Heading as='h2' size='2xl' mb={[2, 4]} textAlign={'left'}>
              <Text as='span' color={colorCategory?.color}>
                {matchedColorRecipe.styleName.slice(0, 1)}
              </Text>
              <Text as='span' color='white'>
                {matchedColorRecipe.styleName.slice(1)}
              </Text>
            </Heading>
            <Flex
              justifyContent='space-between'
              mb={[6, 10]}
              direction={['column', 'row']}
            >
              <Box mb={0}>
                <Text mb={2}>BEFORE：{matchedColorRecipe.before}</Text>
                <Text mb={2}>AFTER：{matchedColorRecipe.after}</Text>
              </Box>
            </Flex>
            <Box mb={[6, 10]}>
              <Heading as='h3' size='lg' mb={[2, 4]}>
                <Text as='span' color={colorCategory?.color}>
                  カ
                </Text>
                ラーレシピ詳細
              </Heading>
              <Grid templateColumns={'1fr'} gap={8} mb={8}>
                <GridItem>
                  <Text
                    fontSize='lg'
                    dangerouslySetInnerHTML={{
                      __html: matchedColorRecipe.colorRecipe,
                    }}
                  ></Text>
                </GridItem>
              </Grid>
            </Box>
            <Box mb={16}>
              <Heading as='h3' size='lg' mb={[2, 4]}>
                <Text as='span' color={colorCategory?.color}>
                  カ
                </Text>
                ラー解説
              </Heading>
              <Text
                fontSize='lg'
                dangerouslySetInnerHTML={{
                  __html: matchedColorRecipe.description,
                }}
              ></Text>
            </Box>
            <Box
              display='flex'
              justifyContent={['center', 'flex-end']}
              mb={[0, 10]}
            >
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Text fontSize='lg' mb={2}>
                  {matchedColorRecipe.salonName}
                </Text>
                <Link
                  href={matchedColorRecipe.instagramUrl}
                  isExternal
                  fontSize='lg'
                >
                  <Image
                    src={`/recepes/${id}-stylist.jpg`}
                    boxSize='200px'
                    borderRadius='full'
                    boxShadow='lg'
                    transition='transform 0.3s'
                    _hover={{ transform: 'scale(1.05)' }}
                    mb={4}
                  />
                </Link>
                <Box display='flex' alignItems='center'>
                  <Text fontSize='lg' mr={2}>
                    Stylist：{matchedColorRecipe.stylistName}
                  </Text>
                  <Link href={matchedColorRecipe.instagramUrl} isExternal>
                    <Image src='/img/ig.png' boxSize={30} />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default ColorRecipeDetailComponent
