import React from 'react'
import Menu from 'react-burger-menu/lib/menus/slide'

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleLinkClick = () => {
    // リンクがクリックされたときの処理
    setIsOpen(false)
  }

  const handleMenuStateChange = (state) => {
    // メニューの状態が変更されたときの処理
    setIsOpen(state.isOpen)
  }

  return (
    <Menu isOpen={isOpen} onStateChange={handleMenuStateChange} right>
      <div id='page-wrap'>
        <div id='menu-wrap'>
          <a href='/gray'>toel Gray</a>
          <a href='#newrelease' onClick={handleLinkClick}>
            NEW RELEASE
          </a>
          <a href='#color-recipe' onClick={handleLinkClick}>
            COLOR RECIPE
          </a>
          <a href='#interview' onClick={handleLinkClick}>
            INTERVIEW
          </a>
          <a href='#dealers' onClick={handleLinkClick}>
            DEALERS
          </a>
        </div>
      </div>
    </Menu>
  )
}

export default BurgerMenu
