import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Image,
  Flex,
  Text,
  Grid,
  useBreakpointValue,
} from '@chakra-ui/react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import FeatureImage from './FeatureImage.tsx'

const hairColors = [
  { code: 'brc', name: 'ブラークール' },
  { code: 'brn', name: 'ブラーナチュラル' },
  { code: 'brw', name: 'ブラーウォーム' },
]

const BlurLineImage = () => {
  const [selectedColor, setSelectedColor] = useState(hairColors[0])
  const sliderRef = useRef<Slider | null>(null)
  const dots = useBreakpointValue({ base: true, lg: false })

  const [settings, setSettings] = useState({
    dots: dots, // ドットインジケーターを表示
    initialSlide: 0, // 初期表示するスライドのインデックス
    infinite: true, // スライドをループさせる
    speed: 500, // スライドのスピード
    slidesToShow: 3, // 1度に表示する数
    slidesToScroll: 1, // 1回のスクロールで何枚の画像を移動するか
    arrows: false, // 左右矢印を非表示
    autoplay: true,
    autoplaySpeed: 5000, // 自動再生のスピード
  })

  const handleColorClick = (color) => {
    // 選択したカラーをセット
    setSelectedColor(color)
    // ドットインジケーターの位置を変更
    const index = hairColors.findIndex((c) => c.code === color.code)
    if (sliderRef.current && sliderRef.current.innerSlider) {
      sliderRef.current.slickGoTo(index)
    }
  }

  useEffect(() => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      dots: dots,
    }))
  }, [dots])

  return (
    <Box
      className='section'
      backgroundImage={{ lg: "url('/img/gray_cover_bg.jpg')" }} // 背景画像のパス
      backgroundSize={{ lg: 'cover' }} // 画像を全面表示
      backgroundPosition={{ lg: 'center' }} // 画像の中央を基準に配置
      backgroundRepeat={{ lg: 'no-repeat' }}
    >
      {/* タイトル */}
      <Text id='blurlineimage' className='gray-title'>
        BLUR LINE IMAGE
      </Text>

      <Box className='featureWrapper'>
        <FeatureImage n={2} className='pcDisplayNone' />
      </Box>

      <Box position='relative'>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          align='center'
          justify='center'
          gap={10}
          pl={{ lg: 8 }}
          pr={{ lg: 8 }}
        >
          {/* 説明文のカラム */}
          <Box flex='2'>
            <Text
              fontSize={{ base: 'md', lg: 'lg' }}
              textAlign={{ base: 'left', lg: 'center' }}
              mb={{ base: 2, lg: 6 }}
              fontWeight={'bold'}
            >
              『明るくオシャレに白髪をぼかして馴染ませたい。』
              <br />
              Blur(ブラー)ライン 10Lv〜11Lv
            </Text>
            <Text
              fontSize='md'
              textAlign={{ base: 'left', lg: 'center' }}
              mb={{ base: 0, lg: 6 }}
            >
              白髪が馴染みやすい寒色系・中寒色系・暖色系の3色調。
              <br />
              複雑なミックスが必要なく、失敗がありません。
              <br />
              単色使いで簡単にファッション性の高い白髪ぼかしができます。
              <br />
            </Text>

            <Grid
              templateColumns='repeat(3, 1fr)'
              alignItems='center'
              gap={2}
              className='spDisplayNone'
            >
              {hairColors.slice(0).map((color) => (
                <Box
                  key={color.code}
                  p={2}
                  opacity={selectedColor.code === color.code ? 1 : 0.5}
                  cursor='pointer'
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => handleColorClick(color)}
                >
                  <Image
                    src={`/img/gray_cover_color_${color.code}.png`}
                    maxW={{ base: '68px', lg: '100px' }}
                    objectFit='cover'
                  />
                  <Text fontSize={{ base: 'xs', lg: 'sm' }} textAlign='center'>
                    {color.name}
                  </Text>
                </Box>
              ))}
            </Grid>
          </Box>

          {/* PC表示時： 画像 + スライダーのカラム */}
          <Box
            flex='1'
            maxW='300px'
            textAlign='center'
            className='spDisplayNone'
          >
            {/* 拡大画像 */}
            <Box display='inline-block' position='relative'>
              <Image
                src={`/img/gray_cover_${selectedColor.code}.png`}
                w='300px'
                objectFit='cover'
              />
              {/* 重ねて表示する画像 */}
              <Image
                src={`/img/gray_cover_color_${selectedColor.code}.png`}
                maxW='50px'
                objectFit='cover'
                position='absolute' // position: absolute を追加
                bottom='5' // 下端に配置
                left='5' // 左端に配置
                zIndex='1' // 重ねて表示する画像の z-index を大きくする
              />
              <Text
                fontSize='xs'
                color='gray'
                position='absolute'
                bottom='0'
                right='0'
              >
                ※色はイメージとなります
              </Text>
            </Box>

            {/* スライダー */}
            <Box mt={2} width='100%'>
              <Slider {...settings} ref={sliderRef}>
                {hairColors.map((color) => (
                  <Box
                    key={color.code}
                    onClick={() => handleColorClick(color)}
                    cursor='pointer'
                  >
                    <Image
                      src={`/img/gray_cover_${color.code}.png`}
                      w='100px'
                      objectFit='cover'
                    />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* SP表示時: 色画像スライダー */}
      <Box display={{ base: 'block', lg: 'none' }} mt={4}>
        {/* 拡大画像 */}
        <Box position='relative' display='inline-block'>
          <Image
            src={`/img/gray_cover_${selectedColor.code}.png`}
            objectFit='cover'
          />
          {/* 重ねて表示する画像 */}
          <Image
            src={`/img/gray_cover_color_${selectedColor.code}.png`}
            maxW='50px'
            objectFit='cover'
            position='absolute' // position: absolute を追加
            bottom='5' // 下端に配置
            left='5' // 左端に配置
            zIndex='1' // 重ねて表示する画像の z-index を大きくする
          />
          <Text
            fontSize='xs'
            color='gray'
            position='absolute'
            bottom='0'
            right='0'
          >
            ※色はイメージとなります
          </Text>
        </Box>

        <Slider {...settings} ref={sliderRef}>
          {hairColors.map((color) => (
            <Box
              key={color.code}
              p={2}
              opacity={selectedColor.code === color.code ? 1 : 0.5}
              cursor='pointer'
              display='flex'
              flexDirection='column'
              alignItems='center'
              textAlign='center'
              onClick={() => handleColorClick(color)}
            >
              <Box display='flex' justifyContent='center'>
                {' '}
                {/* 画像を中央揃えにするためのBoxを追加 */}
                <Image
                  src={`/img/gray_cover_color_${color.code}.png`}
                  maxW='50px'
                  objectFit='cover'
                />
              </Box>
              <Text fontSize='xs' textAlign='center' whiteSpace='nowrap'>
                {color.name}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  )
}

export default BlurLineImage
