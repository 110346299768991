import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'

type Props = {
  setIsOpen: any
  setImgPath: any
}

export const ColorChartBox = (props: Props) => {
  const imgClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      // スマホの場合はクリックイベントを発火させない
      if (window.innerWidth <= 768) return

      props.setImgPath(e.currentTarget.src)
      props.setIsOpen(true)
    },
    [props],
  )
  return (
    <Box className='section'>
      <Box id='colorchart' className='gray-title'>
        COLOR CHART
      </Box>
      <Flex justify={'space-evenly'}>
        <Image
          onClick={(e) => imgClickHandler(e)}
          src='/img/gray_colorchart.png'
        />
      </Flex>
    </Box>
  )
}

export default ColorChartBox
