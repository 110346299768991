import React, { useState } from 'react'
import { Box, Heading, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const PcSection = ({
  title,
  imageSrc,
  hoverImageSrc,
  link,
  align,
  defaultTextColor,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()

  return (
    <Box
      h='80vh'
      className='spDisplayNone'
      flex='1'
      display='flex'
      alignItems='center'
      justifyContent='center'
      bg={isHovered ? (title === 'Fashion' ? 'black' : 'white') : 'gray.500'}
      transition='background-color 0.5s'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor='pointer'
      onClick={() => navigate(link)}
      position='relative'
    >
      <Heading
        size='xl'
        fontFamily='serif'
        color={
          isHovered
            ? title === 'Fashion'
              ? 'white'
              : defaultTextColor
            : defaultTextColor
        }
        position='absolute'
        bottom='20%'
      >
        {title}
      </Heading>
      <Box
        position='absolute'
        top='20%'
        {...(align === 'right' ? { right: '10%' } : { left: '10%' })}
        display='flex'
        alignItems='center'
        zIndex='10'
      >
        <Image
          src={isHovered ? hoverImageSrc : imageSrc}
          alt={title}
          h='80px'
          transition='opacity 0.3s'
        />
      </Box>
    </Box>
  )
}

const PcSections = () => {
  return (
    <>
      <PcSection
        title='Fashion'
        imageSrc='/img/to_black.png'
        hoverImageSrc='/img/to_white.png'
        link='/fashion'
        align='right'
        defaultTextColor='black'
      />
      <PcSection
        title='Gray'
        imageSrc='/img/el_black.png'
        hoverImageSrc='/img/el_black.png'
        link='/gray'
        align='left'
        defaultTextColor='black'
      />
    </>
  )
}

export default PcSections
