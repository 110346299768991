import React, { useRef } from 'react'
import { useInView } from 'framer-motion'

// Chakra UI の Box コンポーネントに framer-motion の機能を結合
export const AnimatedComponentFromDown = ({ children }) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: false })

  return (
    <div ref={ref}>
      <div
        style={{
          transform: isInView ? 'none' : 'translateY(30px)',
          opacity: isInView ? 1 : 0,
          filter: isInView ? 'none' : 'blur(4px)',
          transition: 'all 2s 0s ease',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const AnimatedComponentFromLeft = ({ children }) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: false })

  return (
    <div ref={ref}>
      <div
        style={{
          transform: isInView ? 'none' : 'translateX(-30px)',
          opacity: isInView ? 1 : 0,
          filter: isInView ? 'none' : 'blur(4px)',
          transition: 'all 2s 0s ease',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const AnimatedComponentFromRight = ({ children }) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: false })

  return (
    <div ref={ref}>
      <div
        style={{
          transform: isInView ? 'none' : 'translateX(30px)',
          opacity: isInView ? 1 : 0,
          filter: isInView ? 'none' : 'blur(4px)',
          transition: 'all 2s 0s ease',
        }}
      >
        {children}
      </div>
    </div>
  )
}
