import React from 'react'
import {
  AbsoluteCenter,
  Box,
  Image,
  Center,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'

type Props = {
  n: number
}

export const LineUpImage = (props: Props) => {
  const img = [
    'color_model_1.jpg',
    'color_model_2.jpg',
    'color_model_3.jpg',
    'ek.jpg',
  ]
  const sub = ['アクセント', '明度ライン', 'ロートーン', 'ek:']
  const body = [
    `「高彩度」×「高発色」 一切の濁りを排除したビビッドな
    色味のアクセントカラー。
    流行りのポイントカラーも満足度の高い仕上がりに。`,
    `アクセントでメインカラーとしても、 幅広く使える万能カラー。
    柔らかく透明感のある色味のシアンは、
    今っぽい大人スタイルにもぴったり。`,
    `注目の暗髪も透明感のある仕上がり。
    他の色ともMIXしても濁りを感じさせず、 カラーチェンジもスムーズに`,
    `「韓国風ヘアカラーの最新トレンドを
    クールにオシャレにキメる。」`,
  ]
  const colorCount = [6, 6, 5, 5]

  const colorImages = Array.from(
    { length: colorCount[props.n] },
    (_, i) => i + 1,
  ).map((i) => (
    <Image
      key={i}
      src={`/img/color_${props.n + 1}_${i}.png`}
      ml={'auto'}
      mr={'auto'}
      htmlWidth={'100px'}
    />
  ))

  return (
    <Box mb={10}>
      <Box position={'relative'} mb={5}>
        {/* ekのみnew画像を差し込む */}
        {props.n === 3 ? (
          <Box pos='absolute' right={0} background={'gray'}>
            <Image src='/img/new.png' filter='hue-rotate(180deg)' w={100} />
          </Box>
        ) : null}
        <AbsoluteCenter
          className='featureContent'
          bg={'rgba(0,0,0, 0.6)'}
          axis='both'
        >
          <Box className='featureBoxComp'>
            <Center>
              <Text fontSize='2xl' className='lora'>
                {sub[props.n]}
              </Text>
            </Center>
            <Center mt={3}>
              <Text>{body[props.n]}</Text>
            </Center>
          </Box>
        </AbsoluteCenter>
        <Image src={`/img/${img[props.n]}`} w={'100%'} h={'100%'}></Image>
      </Box>
      <SimpleGrid columns={3} spacing={3}>
        {colorImages}
      </SimpleGrid>
    </Box>
  )
}

export default LineUpImage
