import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Header from '../ui/component/common/Header.tsx'
import HomeFooter from '../ui/component/home/Footer.tsx'
import PcSections from '../ui/component/home/PcSections.tsx'
import SpSections from '../ui/component/home/SpSections.tsx'

const Home = () => {
  return (
    <Box
      minH='100vh'
      backgroundColor='white'
      display='flex'
      flexDirection='column'
    >
      <Header />
      <Flex flex='1'>
        <PcSections />
        <SpSections />
      </Flex>
      <HomeFooter />
    </Box>
  )
}

export default Home
