import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'

export const ToelGray = () => {
  return (
    <Box className='section'>
      <Box id='toelgray' className='gray-title'>
        toel Gray
        <Text
          fontSize={{ base: 'md', lg: 'xl' }}
          display='inline'
          ml={{ base: 10, lg: 20 }}
        >
          COVER / BLUR
        </Text>
      </Box>
      <Box position={'relative'} mb={10}>
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box flex='2' pr={[0, 10]}>
            <Text fontSize='md' mb={2}>
              "重さを感じない色み"と"白髪へのしっかりとした染まり"を両立し、
              <br className='spDisplayNone' />
              白髪に縛られずに、大人世代を楽しめるカラー剤に仕上がった
              <br className='spDisplayNone' />
              トエルの新グレイにカバーライン、ブラーラインが登場。
            </Text>

            <Text
              fontSize={{ base: 'lg', lg: '2xl' }}
              mb={{ base: 2, lg: 4 }}
              mt={{ base: 0, lg: 10 }}
            >
              ●カバーライン
              <br />
              AB・MtB・MB・BB・NB・WB・PB 各7Lv〜2Lv
            </Text>
            <Text fontSize='md' mb={2}>
              重さを感じない色みと白髪への染まりを両立した7色調。
              <br />
              白髪を色濃く、しっかりとカバーすることができます。
            </Text>

            <Text
              fontSize={{ base: 'lg', lg: '2xl' }}
              mb={{ base: 2, lg: 4 }}
              mt={{ base: '0', lg: 10 }}
            >
              ●ブラーライン
              <br />
              brC・brN・brW　各10Lv・11Lv
            </Text>
            <Text fontSize='md' mb={2}>
              白髪が馴染やすい色みに調整された3色調。複雑なミックスの
              <br />
              必要がなく、簡単にファッション性の高い白髪ぼかしができます。
            </Text>
          </Box>
          <Box flex='1' className='spDisplayNone'>
            <Image src='/img/gray_toelgray.png' />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default ToelGray
