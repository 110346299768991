import React from 'react'
import RecipeComponent from '../ui/component/colorRecipe/ColorRecipeGallery.tsx'
import FooterBox from '../ui/component/common/Footer.tsx'
import Header from '../ui/component/common/Header.tsx'

const ColorRecipe = () => {
  return (
    <main>
      <Header />
      <RecipeComponent />
      <FooterBox />
    </main>
  )
}

export default ColorRecipe
