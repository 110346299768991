import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import FeatureBox from './FeatureBox.tsx'
import LineUpBoxSp from './LineUpBoxSp.tsx'
import LineUpBoxPc from './LineUpBoxPc.tsx'
import VideoBox from './VideoBox.tsx'
import BrandBox from './BrandBox.tsx'
import ColorChartBox from './ColorChartBox.tsx'
import InterviewBox from './InterviewBox.tsx'
import Dealer from './Dealer.tsx'
import Footer from '../common/Footer.tsx'
import TreatmentBox from './TreatmentBox.tsx'
import NewRelease from './NewRelease.tsx'
import ColorRecipeBox from './ColorRecipeBox.tsx'
import SideBar from '../common/SideBar.tsx'

type Props = {
  setIsOpen: any
  setImgPath: any
}

export const MainContent = (props: Props) => {
  return (
    <Flex>
      <SideBar />

      <Box>
        {/* VIDEO */}
        <VideoBox device='pc' />
        {/* New Release */}
        <NewRelease />
        {/* BRAND CONCEPT */}
        <BrandBox />
        {/* FEATURE */}
        <FeatureBox />
        {/* LINE UP(SP) */}
        <LineUpBoxSp />
        {/* LINE UP(PC) */}
        <LineUpBoxPc />
        <ColorRecipeBox />
        <ColorChartBox
          setIsOpen={props.setIsOpen}
          setImgPath={props.setImgPath}
        />
        <TreatmentBox />
        <VideoBox device='sp' />
        <InterviewBox />
        <Dealer />
        <Footer />
      </Box>
    </Flex>
  )
}

export default MainContent
