import React from 'react'
import { Box } from '@chakra-ui/react'

export const VideoBox = (props) => {
  let sectionClass = ''
  let id = ''

  if (props.device === 'pc') {
    sectionClass = 'spDisplayNone'
    id = 'video'
  } else if (props.device === 'sp') {
    sectionClass = 'pcDisplayNone'
    id = 'videosp'
  }

  return (
    <Box className={`section ${sectionClass}`}>
      <Box id={id} className='title'>
        VIDEO
      </Box>
      <Box
        as='video'
        controls
        src='/video/toel×DEAN×ONEs.mov'
        poster='/img/poster.jpg'
        objectFit='contain'
        sx={{
          aspectRatio: '16/9',
        }}
      />
    </Box>
  )
}

export default VideoBox
