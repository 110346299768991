import React from 'react'
import { useParams } from 'react-router-dom'
import ColorRecipeDetailComponent from '../ui/component/colorRecipe/ColorRecipeDetailComponent.tsx'
import FooterBox from '../ui/component/common/Footer.tsx'
import Header from '../ui/component/common/Header.tsx'

const ColorRecipeDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <main>
      <Header />
      <ColorRecipeDetailComponent id={id ? parseInt(id) : undefined} />
      <FooterBox />
    </main>
  )
}

export default ColorRecipeDetail
