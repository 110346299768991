import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import CTA from './CTA.tsx'

export const LineUp = () => {
  return (
    <Box className='section'>
      <Box id='lineup' className='gray-title'>
        LINE UP
      </Box>
      <Box mb={{ base: 10, lg: 20 }}>
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box flex='1' display='flex' gap={{ base: 0, lg: 2 }}>
            {/* 1つ目の画像＋テキスト */}
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Image
                src='/img/gray_toelgray.png'
                w='195px'
                objectFit='contain'
              />
              <Text fontSize={{ base: '2xs', lg: 'xs' }}>
                トエルカラーG
                <br />
                1剤/NET.100g/全32色
                <br />
                〔医薬部外品〕
              </Text>
            </Box>

            {/* 2つ目 */}
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Image src='/img/gray_oxy_6.png' w='120px' objectFit='contain' />
              <Text fontSize={{ base: '2xs', lg: 'xs' }}>
                トエルオキシ6%
                <br />
                2剤/NET.1000ml
                <br />
                〔医薬部外品〕
              </Text>
            </Box>

            {/* 3つ目 */}
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Image src='/img/gray_oxy_3.png' w='120px' objectFit='contain' />
              <Text fontSize={{ base: '2xs', lg: 'xs' }}>
                トエルオキシ3%
                <br />
                2剤/NET.1000ml
                <br />
                〔医薬部外品〕
              </Text>
            </Box>
          </Box>

          <Box flex='1'>
            <Text
              fontSize={{ base: 'lg', lg: '2xl' }}
              mb={{ base: 1, lg: 2 }}
              mt={{ base: 3, lg: 0 }}
            >
              ●MC メラニンコントローラー 1剤/100g
            </Text>
            <Text fontSize='md'>
              染まりにくい方やオレンジみが出やすい方の白髪ぼかしに。
              <br className='spDisplayNone' />
              ブラーラインに10〜20％程度MIXするだけで白髪の馴染みがアップ。
              <br className='spDisplayNone' />
              同時に、残留メラニンのオレンジみを打ち消すことが可能。
            </Text>

            <Text
              fontSize={{ base: 'lg', lg: '2xl' }}
              mb={{ base: 1, lg: 2 }}
              mt={{ base: 3, lg: 10 }}
            >
              ●LT ライトナー 1剤/100g
            </Text>
            <Text fontSize='md'>
              ブリーチ剤を使わずに15Lvまでのリフトアップが可能。
              <br />
              ※髪質によります
            </Text>

            <CTA />
          </Box>
        </Flex>
      </Box>

      <Box mb={10}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          mt={{ base: 0, lg: 10 }}
        >
          <Box flex='1' mt={{ base: 0, lg: 10 }}>
            <Text fontSize={{ base: 'lg', lg: '2xl' }} mb={{ base: 2, lg: 2 }}>
              8Lv,9Lvの染まりの向上と、
              <br />
              在庫負担の軽減ができる。
            </Text>
            <Text fontSize='md'>
              単色では白髪への染まりが悪い8Lv,9Lvを、
              <br />
              簡単な加減法使って染まりを向上することができる。
              <br />
              同時に在庫負担の軽減になる。
            </Text>
            <Box className='spDisplayNone'>
              <CTA />
            </Box>
          </Box>

          <Box flex='1' display='flex'>
            <Image src='/img/gray_lineup_89lv.png' objectFit='contain' />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default LineUp
