import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Link, Image, Box, Flex } from '@chakra-ui/react'
import HomeBurgerMenu from '../home/HomeBurgerMenu.tsx'
import FashionBurgerMenu from '../fashion/BurgerMenu.tsx'
import GrayBurgerMenu from '../gray/BurgerMenu.tsx'

export const Header = () => {
  const location = useLocation()

  // パスごとのコンポーネントをマッピング
  const menuComponent =
    {
      '/': <HomeBurgerMenu />,
      '/fashion': <FashionBurgerMenu />,
      '/gray': <GrayBurgerMenu />,
    }[location.pathname] || null

  return (
    <Box as='header'>
      <Flex className='header'>
        {menuComponent}
        <Link as={RouterLink} to='/'>
          <Image
            width='224px'
            height='auto'
            src='/img/logo.png'
            alt='Logo'
            pl={5}
          />
        </Link>
      </Flex>
    </Box>
  )
}

export default Header
