import React from 'react'
import { Box, Image, Text, Flex, SimpleGrid } from '@chakra-ui/react'
import {
  AnimatedComponentFromLeft,
  AnimatedComponentFromRight,
} from '../common/AnimationWrapper.tsx'

export const LineUpBoxPc = () => {
  return (
    <Box className='section spDisplayNone' color='white' gap='30px'>
      <Box id='lineup' className='title'>
        LINE UP
      </Box>
      <Box className='col3'>
        <Flex width='40%' justify='center'>
          <AnimatedComponentFromLeft>
            <Image src='/img/color_model_1.jpg' boxSize={600} fit='cover' />
          </AnimatedComponentFromLeft>
        </Flex>
        <Flex
          direction='column'
          marginTop='60px'
          alignItems='center'
          width='55%'
        >
          <Box textAlign={'center'}>
            <Text fontSize={'3xl'} className='lora'>
              アクセント
            </Text>
            <Text mt={5} fontSize={['md', 'xl']}>
              「高彩度」×「高発色」
              <br />
              一切の濁りを排除したビビッドな
              <br />
              色味のアクセントカラー。
              <br />
              流行りのポイントカラーも満足度の高い仕上がりに。
            </Text>
          </Box>
          <SimpleGrid columns={3} spacing={10} mt={6}>
            <Image src='/img/color_1_1.png' htmlWidth={'100px'} />
            <Image src='/img/color_1_2.png' htmlWidth={'100px'} />
            <Image src='/img/color_1_3.png' htmlWidth={'100px'} />
            <Image src='/img/color_1_4.png' htmlWidth={'100px'} />
            <Image src='/img/color_1_5.png' htmlWidth={'100px'} />
            <Image src='/img/color_1_6.png' htmlWidth={'100px'} />
          </SimpleGrid>
        </Flex>
      </Box>
      <Box className='col3'>
        <Flex
          direction='column'
          marginTop='60px'
          alignItems='center'
          width='55%'
        >
          <Box textAlign={'center'}>
            <Text fontSize={'3xl'} className='lora'>
              明度ライン
            </Text>
            <Text mt={5} fontSize={['md', 'xl']}>
              アクセントでメインカラーとしても、
              <br />
              幅広く使える万能カラー。
              <br />
              柔らかく透明感のある色味のシアンは、
              <br />
              今っぽい大人スタイルにもぴったり。
            </Text>
          </Box>
          <SimpleGrid columns={3} spacing={10} mt={6}>
            <Image src='/img/color_2_1.png' htmlWidth={'100px'} />
            <Image src='/img/color_2_2.png' htmlWidth={'100px'} />
            <Image src='/img/color_2_3.png' htmlWidth={'100px'} />
            <Image src='/img/color_2_4.png' htmlWidth={'100px'} />
            <Image src='/img/color_2_5.png' htmlWidth={'100px'} />
            <Image src='/img/color_2_6.png' htmlWidth={'100px'} />
          </SimpleGrid>
        </Flex>
        <Flex width='40%' justify='center'>
          <AnimatedComponentFromRight>
            <Image src='/img/color_model_2.jpg' boxSize={600} fit='cover' />
          </AnimatedComponentFromRight>
        </Flex>
      </Box>
      <Box className='col3'>
        <Flex width='40%' justify='center'>
          <AnimatedComponentFromLeft>
            <Image src='/img/color_model_3.jpg' boxSize={600} fit='cover' />
          </AnimatedComponentFromLeft>
        </Flex>
        <Flex
          direction='column'
          marginTop='60px'
          alignItems='center'
          width='55%'
        >
          <Box textAlign={'center'}>
            <Text fontSize={'3xl'} className='lora'>
              ロートーン
            </Text>
            <Text mt={5} fontSize={['md', 'xl']}>
              注目の暗髪も透明感のある仕上がり。
              <br />
              他の色ともMIXしても濁りを感じさせず、
              <br />
              カラーチェンジもスムーズに。
            </Text>
          </Box>
          <SimpleGrid columns={3} spacing={10} mt={6}>
            <Image src='/img/color_3_1.png' htmlWidth={'100px'} />
            <Image src='/img/color_3_2.png' htmlWidth={'100px'} />
            <Image src='/img/color_3_3.png' htmlWidth={'100px'} />
            <Image src='/img/color_3_4.png' htmlWidth={'100px'} />
            <Image src='/img/color_3_5.png' htmlWidth={'100px'} />
          </SimpleGrid>
        </Flex>
      </Box>
      <Box className='col3'>
        <Flex
          direction='column'
          marginTop='40px'
          alignItems='center'
          width='55%'
        >
          <Box textAlign={'center'}>
            <AnimatedComponentFromLeft>
              <Image src='/img/new.png' width={'20%'} />
            </AnimatedComponentFromLeft>
            <Text as='span' fontSize={'3xl'} className='lora'>
              ek:
            </Text>
            <Text mt={5} fontSize={['md', 'xl']}>
              「韓国風ヘアカラーの最新トレンドを
              <br />
              クールにオシャレにキメる。」
              <br />
              ★派手髪だけじゃない、ダークトーンや黒髪にも最適。
              <br />
              ★OLや学生、大人世代も挑戦しやすい、
              <br />
              さりげないインナーカラーに。
              <br />
              ★多彩なデザインカラーでさらにオシャレに。
              <br />
              ★高発色で優れた色持ち。（ダブルエフェクト処方）
            </Text>
          </Box>
          <SimpleGrid columns={3} spacing={10} mt={6}>
            <Image src='/img/color_4_1.png' htmlWidth={'100px'} />
            <Image src='/img/color_4_2.png' htmlWidth={'100px'} />
            <Image src='/img/color_4_3.png' htmlWidth={'100px'} />
            <Image src='/img/color_4_4.png' htmlWidth={'100px'} />
            <Image src='/img/color_4_5.png' htmlWidth={'100px'} />
          </SimpleGrid>
        </Flex>
        <Flex width='40%' justify='center'>
          <AnimatedComponentFromRight>
            <Image src='/img/ek.jpg' boxSize={600} fit='cover' />
          </AnimatedComponentFromRight>
        </Flex>
      </Box>
    </Box>
  )
}

export default LineUpBoxPc
